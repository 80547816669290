// this was autogenerated by gulpTimezonePlugin from the timezone source in node_modules
(window.__PRELOADED_TIMEZONE_DATA__ || (window.__PRELOADED_TIMEZONE_DATA__ = {}))['ko_KR']  = {
  "name": "ko_KR",
  "day": {
    "abbrev": [
      "일",
      "월",
      "화",
      "수",
      "목",
      "금",
      "토"
    ],
    "full": [
      "일요일",
      "월요일",
      "화요일",
      "수요일",
      "목요일",
      "금요일",
      "토요일"
    ]
  },
  "month": {
    "abbrev": [
      " 1월",
      " 2월",
      " 3월",
      " 4월",
      " 5월",
      " 6월",
      " 7월",
      " 8월",
      " 9월",
      "10월",
      "11월",
      "12월"
    ],
    "full": [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월"
    ]
  },
  "meridiem": [
    "오전",
    "오후"
  ],
  "date": "%Y년 %m월 %d일",
  "time24": "%H시 %M분 %S초",
  "dateTime": "%x (%a) %r",
  "time12": "%p %I시 %M분 %S초",
  "full": "%Y. %m. %d. (%a) %H:%M:%S %Z"
}

